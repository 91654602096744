
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store,
} from "@/components/CyanPageWrapper.vue";
import { territorios } from "@/modules/cyanRegions";

export default defineComponent({
  name: "PartyMember",
  components: {
    CyanPageWrapper,
    IonPage
  },
  computed: {
    seed(): any {
          return (this as any).$route.params.seed || 'SV'
    },
    idMember(): any {
        return (this as any).$route.params.idMember || 0;
    },
    memberData(): any {
      const s = store.state.partyLists[this.seed as string];

      if (!s || !s.ok) return {};
      const todosMiembros = [...s.organicos, ...s.coordinaciones];

      for (const i of todosMiembros) {
        if (i.id == this.idMember) {
          return i;
        }
      }

      return {};
      //return (this as any).$route.params.memberData || {};
    },

    backRoute() {
      return "/partyList/" + (this as any) /*.$route.params*/.seed;
    },
    tipoConsejo() {
      const s = this.memberData.nivel || "";
      if (s == "") return "";
      if (s == "SV") return "CONSEJO NACIONAL";
      if (s.length <= 4) return "CONSEJO DEPARTAMENTAL";
      if (s.length <= 6) return "CONSEJO MUNICIPAL";
      if (s.length <= 8) return "CONSEJO DISTRITAL";
      return "EQUIPO DE ZONA";    },

    territorio() {
      if (!this.memberData.nivel) return "";
      const n = this.memberData.nivel as any;
      const t = (territorios as any)[n];
      return t.nombrePadre && t.nombrePadre != t.nombre
        ? t.nombre + ", " + t.nombrePadre
        : t.nombre;
    },
    telefonoGuay() {
      const t = this.memberData.telefono as string;
      if (!t) return '';
      if (!t.match(/^[0-9]{8}$/)) return t;
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    telefonoUrl() {
      const t = this.memberData.telefono as string;
      if (!t || t == '') return '';
      let url = 'tel:';
      if (t.match('/^[0-9]{8}$/')) {
        url += t;
      } else {
        // partir en todo lo que no sean números, espacios, guiones, () y el + inicial

        const tieneMas = (t[0] == '+');

        const tTrabajo = tieneMas ? t.substring(1) : t;

        const piezas = tTrabajo.replace(/[^-() 0-9]/g,'|').split('|') as string[];

        if (tieneMas) url += '+';
        url += piezas[0].replace(/[^0-9]/g,'')
      }

      return url;
    },
    
  },
  methods: {},
});
